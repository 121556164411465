.m-0
{
  margin: 0px;
}

.p-0
{
  padding: 0px;
}

/* Colors */

.BGBrandColor {
  background-color: #2f3336;
}
.BGBrandColorPassive {
  background-color: #3c4349;
}

.BGWhitePassive {
  background-color: #F3F3F3;
}

.BGWhite{
  background-color: #FFFFFF;
}

.BGCarouselText
{
  background-color: #00000090;
}

/* Fonts */

.font-spartan-normal {
  font-family: 'League Spartan', sans-serif;
  font-weight: normal;
}

.font-spartan-bold {
  font-family: 'League Spartan', sans-serif;
  font-weight: bold;
}

.font-spartan-black {
  font-family: 'League Spartan', sans-serif;
  font-weight: 900;
}

.font-spartan-light {
  font-family: 'League Spartan', sans-serif;
  font-weight: 300;
}

.font-abeezee-normal {
  font-family: 'ABeeZee', sans-serif;
  font-weight: normal;
}
.font-abeezee-italic {
  font-family: 'ABeeZee', sans-serif;
  font-style: italic;
}

/* Font Color */

.text-gray-1{
  color: #ebebeb;

}
.text-gray-2{
  color: #d3d3d3;
}
.text-gray-3{
  color: #9b9d9e;
}

.text-green-1{
  color: #86C232;
}

/* Text shadow */

.text-shadow-xsoft
{
  text-shadow: 0px 1px 10px #000000;

}
.text-shadow-soft
{
  text-shadow: 0px 2px 30px #000000;
  
}
.text-shadow-medium
{
  text-shadow: 0px 2px 20px #000000;
}
.text-shadow-hard
{
  text-shadow: 0px 2px 10px #000000;
}
.text-shadow-xhard
{
  text-shadow: 0px 2px 5px #000000;
}
.text-shadow-edge
{
  text-shadow: 0px 1px 1px #505050;
}
.text-light-soft
{
  text-shadow: 0px 2px 30px #ffffff;
}
.text-light-medium
{
  text-shadow: 0px 2px 20px #ffffff;
}
.text-light-hard
{
  text-shadow: 0px 2px 10px #ffffff;
}
.text-light-xhard
{
  text-shadow: 0px 2px 5px #ffffff;
}
.text-light-edge
{
  text-shadow: 1px 2px 2px #ffffff;
}


/* Span Line */

.spanLineFull-sm{
  width: 100%;
  border-top:none;
  border-bottom: 1px solid black;
}

.spanLineFull{
  width: 100%;
  border-top:none;
  border-bottom: 2px solid black;
}
.spanLineAltFull{
  width: 100%;
  border-top:none;
  border-bottom: 2px solid #D1D8F7;
}
.spanLineWhiteFull{
  width: 100%;
  border-top:none;
  border-bottom: 2px solid white;
}
.spanLineFull-gray{
  width: 100%;
  border-top:none;
  border-bottom: 2px solid rgb(235, 235, 235);
}
.spanLineFull-gray-sm{
  width: 100%;
  border-top:none;
  border-bottom: 1px solid rgb(235, 235, 235);
}
.spanLine{
  width: 90%;
  border-top: none;
  border-bottom: 2px solid black;
}
.spanLine-sm{
  width: 90%;
  border-top: none;
  border-bottom: 1px solid black;
}
.spanLine-gray{
  width: 90%;
  border-top: none;
  border-bottom: 2px solid rgb(235, 235, 235);
}
.spanLine-black-manual{
  border-top:none;
  border-bottom: 1px solid rgb(0, 0, 0)
}
.spanLine-white-manual{
  border-top:none;
  border-bottom: 1px solid rgb(235, 235, 235);
}
.spanLine-white-manual-2{
  border-top:none;
  border-bottom: 3px solid rgb(235, 235, 235);
}
.spanLine-white-manual-3{
  border-top:none;
  border-bottom: 5px solid rgb(235, 235, 235);
}


/* Utility */

.imgFlip{
  transform: scaleX(-1);
}

.carousel-inner img {
  max-height: 768px;
  min-width: auto;
}

.limit-wrap
{
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden; 
}

.hover-clickable
{
  cursor: pointer;
}

/* Individual CSS */

.carousel-img
{
  min-width: 300px;
  max-width: 1000px;
}

.un-underline
{
  text-decoration: none;
}